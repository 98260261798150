import React from 'react';
import styled from 'styled-components';

const StepTitleCircle = styled.span`
	height: 32px;
	width: 32px;
	border: 1px solid ${props => props.theme.neutrals.lightOne};
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 32px;
	border-radius: 100%;
	color: ${props => props.theme.neutrals.light};
	@media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
		height: 42px;
		width: 42px;
		font-size: 20px;
		flex-basis: 42px;
	}
`;

const StepTitleLabel = styled.span`
	margin-left: 8px;
	flex: 0 0 calc(100% - 40px);
	@media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
		margin-left: 12px;
		flex-basis: calc(100% - 54px);
	}
`;

export const StepTitleWrap = styled.h2`
	margin: 0 0 ${props => (props.withTab ? 14 : props.theme.gutter.small)}px 0;
	color: ${props => props.theme.neutrals.darkest};
	font-size: 18px;
	line-height: 1.2;
	display: flex;
	align-items: center;
	flex-flow: row nowrap;
	font-weight: 600;
	@media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
		font-size: 24px;
		margin-bottom: ${props =>
			props.withTab ? 14 : props.theme.gutter.large}px;
	}
`;

export default function StepTitle({ step = 1, withTab = false, children }) {
	return (
		<StepTitleWrap withTab={withTab}>
			<StepTitleCircle>{step}</StepTitleCircle>
			<StepTitleLabel>{children}</StepTitleLabel>
		</StepTitleWrap>
	);
}
