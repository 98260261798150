import React, { useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';

import { StepTitleWrap } from '../StepTitle';

const ControlWrap = styled.section`
	margin: 0 0 ${props => props.theme.gutter.small}px 0;

	@media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
		margin-bottom: ${props => props.theme.gutter.large}px;
		padding-left: ${props => (props.noPadding === true ? 0 : '54px')};
		${StepTitleWrap} {
			margin-left: -54px;
		}
	}
`;

export default function Control({
	children,
	scrollOnMount = false,
	...restProps
}) {
	const ref = useRef();
	useLayoutEffect(() => {
		if (scrollOnMount && ref.current) {
			const domElement = ref.current;
			setTimeout(() => {
				scrollToElement(domElement, {
					offset: -20,
					align: 'top',
					ease: 'out-sine',
					duration: 700,
				});
			}, 100);
		}
	}, [scrollOnMount]);
	return (
		<ControlWrap {...restProps} ref={ref}>
			{children}
		</ControlWrap>
	);
}
