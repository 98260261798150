import React, { useRef, useState, useContext, useLayoutEffect } from 'react';
import { useRect } from '@reach/rect';
import {
	Tabs as ReachTabs,
	Tab as ReachTab,
	TabList as ReachTabList,
	TabPanels as ReachTabPanels,
} from '@reach/tabs';
import styled from 'styled-components';

import '@reach/tabs/styles.css';

const AnimatedContext = React.createContext();

const TabBar = styled.div`
	position: absolute;
	height: 2px;
	background: ${props => props.theme.primary.dark};
	margin-top: -2px;
	transition: all 300ms ${props => props.theme.effects.easeOutBack};
	border-radius: 2px;
`;

const TabBarFull = styled.div`
	position: absolute;
	height: 2px;
	background: ${props => props.theme.neutrals.lightOne};
	margin-top: -2px;
	left: 8px;
	right: 8px;
	border-radius: 2px;
`;

const StyledReachTabs = styled(ReachTabs)`
	&[data-reach-tabs] {
		width: 100%;
		padding: 0 8px;
		overflow: hidden;
		margin: 0 0 ${props => props.theme.gutter.small}px 0;

		@media screen and (min-width: ${props =>
				props.theme.breakpoints.tablet}) {
			margin-bottom: ${props => props.theme.gutter.large}px;
		}
	}
`;

const StyledReachTabList = styled(ReachTabList)`
	&[data-reach-tab-list] {
		background: ${props => props.theme.background};
		margin: 0 -8px;
		overflow-x: auto;
		height: 34px;
		&::before,
		&::after {
			content: '';
			width: 8px;
			flex: 0 0 8px;
		}
	}
`;

const StyledReachTab = styled(ReachTab)`
	&[data-reach-tab] {
		font-size: 16px;
		padding: 8px;
		padding-right: 20px;
		border: 0 none;
		color: ${props => props.theme.neutrals.light};
		transition: all 300ms ease-out;
		word-break: keep-all;
		white-space: nowrap;
		&:hover {
			color: ${props => props.theme.neutrals.darker};
		}
		&:active {
			background: ${props => props.theme.neutrals.lightest};
		}
		&[aria-selected='true'] {
			color: ${props => props.theme.primary.dark};
		}
	}
`;

const StyledTabPanels = styled(ReachTabPanels)`
	&[data-reach-tab-panels] {
		padding: ${props => props.theme.gutter.small}px 0;
	}
`;

// we override the TabList because we wan't the click/auto-scroll behavior
// when user activates a tab, the selectedRect changes and depending on that
// we want to change the scrollLeft of the TabList DOM.
export function TabList({ children, ...props }) {
	// create a ref for TabList DOM
	const tabListRef = useRef();
	// get selected Rect
	const { tabIndex } = useContext(AnimatedContext);
	// whenever selectedRect changes, scroll to the active element
	useLayoutEffect(() => {
		const { current: elm } = tabListRef;
		if (elm === null) {
			return;
		}
		// find the one that has the active class and keep on adding width + gutter
		// until it reaches
		let scrollLeft = 0;
		const tabItems = Array.from(elm.querySelectorAll('button'));
		tabItems.every((tab, index) => {
			if (tabIndex === index) {
				return false;
			}
			scrollLeft += tab.offsetWidth + 16;
			return true;
		});
		elm.scrollLeft = scrollLeft;
	}, [tabIndex]);

	return (
		<StyledReachTabList ref={tabListRef} {...props}>
			{children}
		</StyledReachTabList>
	);
}

export function Tabs(props) {
	// need to store the position of the selected Tab so we can
	// animate the bar to its position
	const [selectedRect, setSelectedRect] = useState(null);
	const [tabIndex, setTabIndex] = useState(props.index);

	// need to measure the parent element so we can measure
	// the relative "left" for the bar
	const tabsRef = useRef();
	const tabsRect = useRect(tabsRef);

	// Put the function to change the positions on context so the
	// Tabs down the tree can easily access it
	return (
		<AnimatedContext.Provider
			value={{ selectedRect, setSelectedRect, tabsRect, tabIndex }}
		>
			<StyledReachTabs
				{...props}
				ref={tabsRef}
				style={{ ...props.style, position: 'relative' }}
				onChange={index => {
					setTabIndex(index);
					if (typeof props.onChange === 'function') {
						props.onChange(index);
					}
				}}
			>
				{props.children[0]}

				{/* put the bar inbetween the TabList and TabPanels */}
				<TabBarFull />
				<TabBar
					style={{
						// Here is the actual animation part, we use the
						// rect from the selected tab to set the styles of the bar
						left: selectedRect && selectedRect.left - tabsRect.left,
						width: selectedRect && selectedRect.width,
					}}
				/>

				{props.children[1]}
			</StyledReachTabs>
		</AnimatedContext.Provider>
	);
}

export function Tab(props) {
	const { isSelected } = props;

	// Each tab measures itself
	const ref = useRef();
	const rect = useRect(ref, isSelected);

	// and calls up to the parent when it becomes selected
	// we useLayoutEffect to avoid flicker
	const { setSelectedRect } = useContext(AnimatedContext);
	useLayoutEffect(() => {
		if (isSelected) setSelectedRect(rect);
	}, [isSelected, rect, setSelectedRect]);

	return <StyledReachTab ref={ref} {...props} />;
}

export { StyledTabPanels as TabPanels };

export { TabPanel } from '@reach/tabs';
