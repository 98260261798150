import React, { useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuid4 } from 'uuid';

import { ReactComponent as CheckIcon } from '../../svgs/icons/checkbox-check.svg';

const CheckboxLabel = styled.label`
	color: ${props => props.theme.neutrals.secondaryText};
	display: flex;
	flex-flow: row nowrap;
	cursor: pointer;
	margin-left: 4px;
	position: relative;

	.checkbox__field {
		position: relative;
		height: 24px;
		width: 24px;
		flex: 0 0 24px;
		background: ${props => props.theme.background};
		border: 2px solid ${props => props.theme.neutrals.lightOne};
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 14px;
		transition: all 300ms ${props => props.theme.effects.easeOutSine};
		box-shadow: 0 0 0 1px transparent, 0 0 4px 0 transparent;

		svg {
			height: 15px;
			width: 15px;
			color: ${props => props.theme.background};
			opacity: 0;
			transition: all 200ms ${props => props.theme.effects.easeOutSine};
		}
	}

	input {
		cursor: pointer;
		position: absolute;
		opacity: 0;
		height: 24px;
		width: 24px;
		z-index: 2;
		left: 0;
		top: 0;
	}

	input:checked + .checkbox__field {
		svg {
			opacity: 1;
		}
		// background-color: ${props => props.theme.primary.dark};
		background-color: #176ce8;
		border-color: #176ce8;
		// border-color: ${props => props.theme.primary.dark};
	}

	.checkbox__label {
		flex: 0 0 calc(100% - 46px);
		max-width: 650px;
		font-size: 18px;
		line-height: 1.5;
	}

	&:hover {
		.checkbox__field {
			/* box-shadow: 0 1px 1px 0 ${props => props.theme.neutrals.light},
				0 0 3px 0 ${props => props.theme.neutrals.light}; */
			border-color: ${props => props.theme.neutrals.secondaryText};
		}
	}

	@media ${props => props.theme.breakpoints.tabletQuery} {
		margin-left: -22px;
		.checkbox__field {
			margin-right: 22px;
		}
	}
`;

export default function Checkbox({ checked, onChange, children }) {
	const instance = useRef(null);
	if (instance.current === null) {
		instance.current = uuid4();
	}
	const { current: id } = instance;
	return (
		<CheckboxLabel htmlFor={id}>
			<input
				type="checkbox"
				checked={checked}
				onChange={e => onChange(e)}
				id={id}
			/>
			<div className="checkbox__field">
				<CheckIcon />
			</div>
			<div className="checkbox__label">{children}</div>
		</CheckboxLabel>
	);
}
