import styled from 'styled-components';

export const ItemsGrid = styled.div`
	display: grid;
	grid-gap: ${props => props.theme.gutter.small}px;
	grid-template-columns: repeat(
		auto-fill,
		minmax(${props => props.minWidth || '252px'}, 1fr)
	);
`;
