import React, { useState } from 'react';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import StepTitle from '../../components/StepTitle';
import { Tab, Tabs, TabList, TabPanel, TabPanels } from '../../components/Tabs';
import Control from '../../components/Control';
import { OSItem, OSItems } from '../../components/OSItems';
import { AppItems, AppItem } from '../../components/AppItems';
import { PricingTables, PricingTable } from '../../components/PricingTable';
import Checkbox from '../../components/Checkbox';
import LaunchFooter from '../../components/LaunchFooter';
import SEO from '../../components/SEO';

export default function SiteIndex(props) {
	const [checked, setChecked] = useState(false);
	return (
		<Layout {...props}>
			<SEO title="Some Sample Page" />
			<Header tooltip="Some super awesome tooltip">
				Some fun header text
			</Header>
			<Control>
				<StepTitle step="1" withTab>
					Select your Linux Distribution
				</StepTitle>
				<Tabs>
					<TabList>
						<Tab>Control Panels</Tab>
						<Tab>Content Management Systems</Tab>
						<Tab>Cloud Storage</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<OSItems>
								<OSItem
									icon="ubuntu"
									title="Ubuntu"
									active
									versions={[
										{
											id: 'ubuntu-64-18.10',
											label: '18.10 x64',
										},
										{
											id: 'ubuntu-64-16.10',
											label: '16.10 x64',
										},
										{
											id: 'ubuntu-64-14.04',
											label: '14.04 x64',
										},
									]}
									activeVersion="ubuntu-64-16.10"
								/>
								<OSItem
									icon="fedora"
									title="Fedora"
									versions={[
										{
											id: 'fedora-64-18.10',
											label: '18.10 x64',
										},
									]}
									activeVersion="ubuntu-64-16.10"
								/>
							</OSItems>
						</TabPanel>
						<TabPanel>
							<p>Yeah yeah. What&apos;s up?</p>
						</TabPanel>
						<TabPanel>
							<p>The third yab?</p>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Control>
			<Control>
				<StepTitle step="2">Customize your Ubuntu server</StepTitle>
				<AppItems>
					<AppItem active icon="cpanelwhm" title="cPanel WHM" />
					<AppItem icon="docker" title="Docker" />
					<AppItem icon="gitlab" title="GitLab" />
					<AppItem icon="joomla" title="Joomla" />
					<AppItem icon="lamp" title="LAMP" />
					<AppItem icon="lemp" title="LEMP" />
					<AppItem icon="magento" title="Magento" />
				</AppItems>
			</Control>
			<Control>
				<StepTitle step="3">Choose a Size - LAMP</StepTitle>
				<PricingTables>
					<PricingTable
						id="ubuntu-pricing"
						title="50 GB"
						price="$99.99/mo"
						banner="FBH"
						features={[
							'1TB Bandwidth',
							'1024 MB RAM',
							'1 Core CPU',
						]}
						rrp="$120.99/mo"
					/>
					<PricingTable
						active
						id="ubuntu-pricing"
						title="50 GB"
						price="$99.99/mo"
						banner="FBH"
						features={[
							'1TB Bandwidth',
							'1024 MB RAM',
							'1 Core CPU',
						]}
						rrp="$120.99/mo"
					/>
				</PricingTables>
			</Control>
			<Control>
				<StepTitle step="4">Read User Guide</StepTitle>
				<Checkbox
					checked={checked}
					onChange={e => setChecked(e.target.checked)}
				>
					<div
						dangerouslySetInnerHTML={{
							__html:
								'I understand there is a temporary SSL browser warning when I first access my server. Refer to <a href="https://help.hostjane.com/vps-hosting/">server guides</a> for how to set up your server.',
						}}
					/>
				</Checkbox>
			</Control>
			<LaunchFooter
				summary="$99.99/mo"
				label="cPanel and long line Lamp On 50GB Ubuntu"
				link="#"
			/>
		</Layout>
	);
}
