import React, { useLayoutEffect, useRef } from 'react';
import scrollToElement from 'scroll-to-element';
import styled from 'styled-components';
import Container from '../Container';
import ButtonLink from '../ButtonLink';

const FooterWrap = styled.footer`
	padding: 0 30px;
	margin: 0 0 ${props => props.theme.gutter.small}px 0;

	@media ${props => props.theme.breakpoints.tabletQuery} {
		position: fixed;
		z-index: 9998; /* one less than siteNav */
		bottom: 0;
		left: 0;
		margin: 0;
		height: 90px;
		width: 100%;
		padding: 20px 20px 20px 130px;
		background: ${props => props.theme.background};
		box-shadow: 2px -1px 2px 0 ${props => props.theme.neutrals.lightOne},
			6px 0 4px 0 ${props => props.theme.neutrals.lightOne};
	}
`;

const FooterFlex = styled(Container)`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	@media ${props => props.theme.breakpoints.tabletQuery} {
		flex-flow: row nowrap;
	}
`;

const FooterSummary = styled.aside`
	flex: 0 0 100%;
	text-align: center;
	margin: 0 0 20px 0;
	p {
		margin: 0;
		padding: 0;
		line-height: 1;

		&.launch-footer__summarylabel {
			position: absolute;
			left: -99999px;
			color: ${props => props.theme.neutrals.light};
			font-size: 16px;
			margin-bottom: 8px;
		}
		&.launch-footer__summaryvalue {
			color: ${props => props.theme.primary.dark};
			font-size: 22px;
			line-height: 22px;
			font-weight: bold;
		}
	}

	@media ${props => props.theme.breakpoints.tabletQuery} {
		flex-basis: auto;
		margin: 0 15px 0 0;

		p.launch-footer__summarylabel {
			position: static;
			left: auto;
		}
	}
`;

const FooterLabel = styled.aside`
	text-align: center;
	flex: 0 0 100%;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.2;
	margin: 0 0 20px 0;
	color: ${props => props.theme.neutrals.light};
	@media ${props => props.theme.breakpoints.tabletQuery} {
		flex-basis: auto;
		flex-shrink: 1;
		margin: 0 auto;
	}
`;

const FooterButtonWrap = styled.div`
	flex: 0 0 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	@media ${props => props.theme.breakpoints.tabletQuery} {
		flex-basis: 250px;
		margin: 0 0 0 15px;
	}
	@media ${props => props.theme.breakpoints.desktopQuery} {
		flex-basis: 350px;
	}
`;

export default function LaunchFooter({
	summary,
	label,
	link,
	target = '_self',
	scrollOnMount = false,
}) {
	const ref = useRef();
	useLayoutEffect(() => {
		if (scrollOnMount && ref.current) {
			scrollToElement(ref.current, {
				offset: -20,
				align: 'top',
				ease: 'out-sine',
				duration: 700,
			});
		}
	}, [scrollOnMount]);
	return (
		<FooterWrap ref={ref}>
			<FooterFlex>
				<FooterSummary>
					<p className="launch-footer__summarylabel">Experience level:</p>
					<p className="launch-footer__summaryvalue">{summary}</p>
				</FooterSummary>
				<FooterLabel>{label}</FooterLabel>
				<FooterButtonWrap>
					<ButtonLink backgroundBlue target={target} href={link}>
						View freelancer
					</ButtonLink>
				</FooterButtonWrap>
			</FooterFlex>
		</FooterWrap>
	);
}
