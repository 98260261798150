import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { ReactComponent as CentOSIcon } from '../../svgs/wizard/OS/centOS.svg';
import { ReactComponent as CoreOSIcon } from '../../svgs/wizard/OS/coreOS.svg';
import { ReactComponent as DebianIcon } from '../../svgs/wizard/OS/debian.svg';
import { ReactComponent as FedoraIcon } from '../../svgs/wizard/OS/fedora.svg';
import { ReactComponent as FreeBSDIcon } from '../../svgs/wizard/OS/freeBSD.svg';
import { ReactComponent as UbuntuIcon } from '../../svgs/wizard/OS/ubuntu.svg';
import { ReactComponent as CheckIcon } from '../../svgs/icons/check.svg';
import { ReactComponent as ArabicIcon } from '../../svgs/wizard/Apps/arabic.svg';
import { ReactComponent as FrenchIcon } from '../../svgs/wizard/Apps/french.svg';
import { ReactComponent as GermanIcon } from '../../svgs/wizard/Apps/german.svg';
import { ReactComponent as ItalianIcon } from '../../svgs/wizard/Apps/italian.svg';
import { ReactComponent as JapanIcon } from '../../svgs/wizard/Apps/japan.svg';
import { ReactComponent as PortugalIcon } from '../../svgs/wizard/Apps/portugal.svg';
import { ReactComponent as SpanishIcon } from '../../svgs/wizard/Apps/spanish.svg';
import { ReactComponent as EnglishIcon } from '../../svgs/wizard/Apps/english.svg';
import { ReactComponent as DutchIcon } from '../../svgs/wizard/Apps/dutch.svg';
import { ReactComponent as ChineseIcon } from '../../svgs/wizard/Apps/chinese.svg';
import { ReactComponent as BengaliIcon } from '../../svgs/wizard/OS/bengali.svg';
import { ReactComponent as CzechIcon } from '../../svgs/wizard/OS/czech.svg';
import { ReactComponent as FinnishIcon } from '../../svgs/wizard/OS/finland.svg';
import { ReactComponent as GreekIcon } from '../../svgs/wizard/OS/greek.svg';
import { ReactComponent as HebrewIcon } from '../../svgs/wizard/OS/hebrew.svg';
import { ReactComponent as HindiIcon } from '../../svgs/wizard/OS/hindi.svg';
import { ReactComponent as IndonesianIcon } from '../../svgs/wizard/OS/indonesia.svg';
import { ReactComponent as KoreanIcon } from '../../svgs/wizard/OS/korea.svg';
import { ReactComponent as HausaYorubaIcon } from '../../svgs/wizard/OS/nigeria.svg';
import { ReactComponent as PolishIcon } from '../../svgs/wizard/OS/poland.svg';
import { ReactComponent as RussianIcon } from '../../svgs/wizard/OS/russian.svg';
import { ReactComponent as SwedishIcon } from '../../svgs/wizard/OS/swedish.svg';
import { ReactComponent as UkraineIcon } from '../../svgs/wizard/OS/ukraine.svg';
import { ReactComponent as UrduIcon } from '../../svgs/wizard/OS/urdu.svg';
import { ReactComponent as VietnamIcon } from '../../svgs/wizard/OS/vietnam.svg';

const iconMap = {
	Arabic: <ArabicIcon />,
	German: <GermanIcon />,
	French: <FrenchIcon />,
	Japan: <JapanIcon />,
	Chinese: <ChineseIcon />,
	Italian: <ItalianIcon />,
	Portugal: <PortugalIcon />,
	Spanish: <SpanishIcon />,
	English: <EnglishIcon />,
	Dutch: <DutchIcon />,
	Bengali: <BengaliIcon />,
	Czech: <CzechIcon />,
	Finnish: <FinnishIcon />,
	Greek: <GreekIcon />,
	Hebrew: <HebrewIcon />,
	Hindi: <HindiIcon />,
	Indonesian: <IndonesianIcon />,
	Korean: <KoreanIcon />,
	HausaYoruba: <HausaYorubaIcon />,
	Polish: <PolishIcon />,
	Russian: <RussianIcon />,
	Swedish: <SwedishIcon />,
	Ukraine: <UkraineIcon />,
	Urdu: <UrduIcon />,
	Vietnam: <VietnamIcon />,


	centOS: <CentOSIcon />,
	coreOS: <CoreOSIcon />,
	debian: <DebianIcon />,
	fedora: <FedoraIcon />,
	freeBSD: <FreeBSDIcon />,
	ubuntu: <UbuntuIcon />,
};

export { ItemsGrid as OSItems } from '../ItemsGrid';

const OSIcon = styled.div`
	height: 56px;
	width: 56px;
	flex: 0 0 56px;
	svg {
		height: 100%;
		width: 100%;
		transition: filter 300ms ${props => props.theme.effects.easeOutSine};
		filter: grayscale(1);
	}
`;

const OSLabel = styled.div`
	flex: 0 0 calc(100% - 72px);
	margin-left: 16px;
`;

const OSTitle = styled.h3`
	font-size: 16px;
	line-height: 1;
	font-weight: bold;
	color: ${props => props.theme.neutrals.darkest};
	margin: 0 0 10px 0;
	padding: 0;
`;

const OSSubtitle = styled.p`
	font-size: 16px;
	line-height: 12px;
	color: ${props => props.theme.neutrals.light};
	margin: 0;
	padding: 0;
`;

const OSVersionWrap = styled.div`
	transition: all 400ms ${props => props.theme.effects.easeOutBack};
	transform: translateX(-50px);
	opacity: 0;
`;

const OSVersion = styled.button`
	border: 0 none;
	background: transparent;
	width: auto;
	white-space: nowrap;
	word-break: keep-all;
	display: flex;
	flex-flow: row nowrap;
	font-size: 16px;
	line-height: 16px;
	padding: 6px 10px 6px 0;
	color: ${props => props.theme.neutrals.light};
	justify-content: space-between;
	align-items: center;
	text-align: left;
	cursor: pointer;
	transition: color 300ms ease-out, transform 300ms ease-out;

	span.version-label {
		flex: 1 0 auto;
		margin-right: 15px;
	}
	span.version-icon {
		flex: 1 0 auto;
		display: flex;
		align-items: center;
		width: 10px;
		height: 8px;
		color: ${props => props.theme.neutrals.lightOne};
		transition: all 300ms ease-out;
		svg {
			width: 10px;
			height: 8px;
			fill: currentColor;
		}
	}

	&:hover {
		color: ${props => props.theme.neutrals.darker};
		transform: scale(1.05, 1.05);
		span.version-icon {
			color: ${props => props.theme.neutrals.secondaryText};
		}
	}

	&.active {
		color: ${props => props.theme.primary.dark};
		font-weight: bold;
		span.version-icon {
			color: ${props => props.theme.supporting.success};
		}
	}
`;

const OSVersions = styled.div`
	/*
	* 1. Adjusted left margin from 80px (taking into padding)
	* 2. 10px left padding so that scale animation doesn't hide on overflow
	*/
	flex: 0 0 auto;
	margin-left: 70px; /* 1 */
	height: 0;
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
	overflow: hidden;
	display: ${props => (props.total > 1 ? 'block' : 'none')};

	&.active {
		height: ${props => 15 + props.total * 28}px;
		padding: 0 20px 15px 10px; /* 2 */
		${OSVersionWrap} {
			transform: translateX(0);
			opacity: 1;
			${props => {
				const { total } = props;
				let style = '';
				for (let i = 0; i < total; i++) {
					style += `&:nth-child(${i + 1}) {
						transition-delay: ${i * 100}ms;
					}`;
				}
				return style;
			}}
		}
	}
`;

const OSItemWrap = styled.div`
	background: ${props => props.theme.background};
	box-shadow: 0 0 0 1px ${props => props.theme.neutrals.lightTwo},
		0 0 4px 0 transparent;
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
	border-radius: 2px;

	&:hover {
		box-shadow: 0 1px 2px 0 ${props => props.theme.neutrals.lightOne},
			0 0 4px 0 ${props => props.theme.neutrals.lightOne};
		${OSIcon} {
			svg {
				filter: grayscale(0);
			}
		}
	}

	&:active,
	&.active {
		box-shadow: 0 1px 2px 0 ${props => props.theme.neutrals.lightOne},
			0 0 6px 0 ${props => props.theme.neutrals.lightOne};
		${OSIcon} {
			svg {
				filter: grayscale(0);
			}
		}
	}
`;

const OSItemCover = styled.div`
	min-height: 85px;
	padding: 8px;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	cursor: pointer;
`;

const OSSingleVersion = styled.span`
	color: inherit;
	transition: color 300ms ease-out;

	&.active {
		color: ${props => props.theme.primary.dark};
		font-weight: bold;
	}
`;

export function OSItem({
	active = false,
	icon,
	title,
	versions,
	activeVersion,
	onCoverClick,
	onVersionClick,
}) {
	let versionLabel = 'Service options';
	if (versions.length === 1) {
		versionLabel = (
			<OSSingleVersion className={classNames({ active })}>
				{versions[0].label}
			</OSSingleVersion>
		);
	}
	return (
		<div>
			<OSItemWrap className={classNames({ active })}>
				<OSItemCover tabIndex="0" onClick={onCoverClick}>
					<OSIcon>{iconMap[icon]}</OSIcon>
					<OSLabel>
						<OSTitle>{title}</OSTitle>
						<OSSubtitle>{versionLabel}</OSSubtitle>
					</OSLabel>
				</OSItemCover>

				<OSVersions
					total={versions.length}
					className={classNames({ active })}
				>
					{versions.map(v => (
						<OSVersionWrap key={v.key}>
							<OSVersion
								className={classNames({
									active: v.id === activeVersion,
								})}
								onClick={() => onVersionClick(v.id)}
							>
								<span className="version-label">{v.label}</span>
								<span className="version-icon">
									<CheckIcon />
								</span>
							</OSVersion>
						</OSVersionWrap>
					))}
				</OSVersions>
			</OSItemWrap>
		</div>
	);
}
