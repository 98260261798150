import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

export { ItemsGrid as PricingTables } from '../ItemsGrid';

const PricingTableHeader = styled.header`
	padding: 15px;
	position: relative;
	overflow: hidden; /* to hide the banner ribbon overflown zone */
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	flex-basis: 100%;
	border-radius: 2px 2px 0 0;
	border-bottom: 1px solid ${props => props.theme.neutrals.lightTwo};
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
`;

const PricingTableTitle = styled.h3`
	flex: 0 0 100%;
	text-align: center;
	font-size: 18px;
	line-height: 1;
	font-weight: bold;
	color: ${props => props.theme.neutrals.darkest};
	margin: 0;
	padding: 0;
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
`;

const PricingTablePrice = styled.h4`
	flex: 0 0 100%;
	text-align: center;
	font-size: 22px;
	font-weight: 400;
	line-height: 1;
	color: ${props => props.theme.primary.dark};
	margin: 8px 0 0 0;
	padding: 0;
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
`;

const PricingTableBanner = styled.aside`
	transform: rotate(-315deg);
	transform-origin: top center;
	background: ${props => props.theme.primary.light};
	text-align: center;
	width: 108px;
	height: 45px;
	position: absolute;
	top: 0;
	right: -54px;
	color: ${props => props.theme.background};
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
	line-height: 62px;
	font-size: 12px;
`;

const PricingTableFeatureList = styled.ul`
	position: relative; /* because we have ::before backdrop */
	list-style: none;
	margin: 0;
	padding: 15px;
	font-size: 18px;
	line-height: 25px;
	color: ${props => props.theme.neutrals.darkest};
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
	width: 100%;
	flex-basis: 100%;
`;

const PricingTableFeatureListItem = styled.li`
	text-align: center;
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
`;

const PricingTableFooter = styled.footer`
	position: relative; /* because we have ::before backdrop */
	padding: 15px;
	margin-top: 15px;
	font-size: 16px;
	line-height: 1;
	color: ${props => props.theme.primary.dark};
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
	width: 100%;
	flex-basis: 100%;
	text-align: center;
`;

const PricingTableWrap = styled.div`
	padding: 0;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	cursor: pointer;
	background: ${props => props.theme.background};
	box-shadow: 0 0 0 1px ${props => props.theme.neutrals.lightTwo},
		0 0 4px 0 transparent;
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
	border-radius: 2px;
	position: relative;

	&::before {
		position: absolute;
		content: '';
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: linear-gradient(
			90deg,
			rgb(26, 115, 232) 0%,
			rgb(9 56 226) 100%
		);
		border-color: #1e88e5;
		opacity: 0;
		transition: all 300ms ${props => props.theme.effects.easeOutSine};
		border-radius: 2px;
	}

	&:hover {
		box-shadow: 0 1px 2px 0 ${props => props.theme.neutrals.lightOne},
			0 0 4px 0 ${props => props.theme.neutrals.lightOne};
	}

	&&:active,
	&&.active {
		box-shadow: 0 1px 2px 0 ${props => props.theme.neutrals.lightOne},
			0 0 6px 0 ${props => props.theme.neutrals.lightOne};
	}
	&.active {
		&::before {
			opacity: 1;
		}
		${PricingTableHeader} {
			// border-bottom-color: ${props => props.theme.primary.dark};
		}

		${PricingTableTitle},
		${PricingTablePrice},
		${PricingTableFeatureList},
		${PricingTableFooter} {
			color: ${props => props.theme.background};
		}

		${PricingTableBanner} {
			background: ${props => props.theme.primary.darkest};
		}
	}
`;

export function PricingTable({
	active = false,
	title,
	price,
	banner,
	features,
	rrp,
	onClick,
}) {
	return (
		<div>
			<PricingTableWrap
				tabIndex="0"
				onClick={onClick}
				className={classNames({ active })}
			>
				<PricingTableHeader>
					<PricingTableTitle>{title}</PricingTableTitle>
					<PricingTablePrice>{price}</PricingTablePrice>
					{banner !== null ? (
						<PricingTableBanner>{banner}</PricingTableBanner>
					) : null}
				</PricingTableHeader>
				<PricingTableFeatureList>
					{features.map((f, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<PricingTableFeatureListItem key={i}>
							{f}
						</PricingTableFeatureListItem>
					))}
				</PricingTableFeatureList>
				<PricingTableFooter>{rrp}</PricingTableFooter>
			</PricingTableWrap>
		</div>
	);
}
