import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { ReactComponent as BusinessFormationIcon } from '../../svgs/wizard/OS/business-formation.svg';
import { ReactComponent as ContractsIcon } from '../../svgs/wizard/OS/contracts.svg';
import { ReactComponent as ConsultingIcon } from '../../svgs/wizard/OS/consulting.svg';
import { ReactComponent as ImmigrationIcon } from '../../svgs/wizard/OS/immigration.svg';
import { ReactComponent as TrademarksIcon } from '../../svgs/wizard/OS/trademarks.svg';
import { ReactComponent as TaxIcon } from '../../svgs/wizard/OS/tax.svg';
import { ReactComponent as BooksCleanupIcon } from '../../svgs/wizard/OS/books-cleanup.svg';
import { ReactComponent as BookkeepingIcon } from '../../svgs/wizard/OS/bookkeeping.svg';
import { ReactComponent as QuickbooksIcon } from '../../svgs/wizard/OS/quickbooks.svg';
import { ReactComponent as PayrollIcon } from '../../svgs/wizard/OS/payroll.svg';
import { ReactComponent as TaxPrepIcon } from '../../svgs/wizard/OS/tax-prep.svg';
import { ReactComponent as CloudAccountsIcon } from '../../svgs/wizard/OS/cloud-accounting.svg';
import { ReactComponent as RetirementIcon } from '../../svgs/wizard/OS/retirement.svg';
import { ReactComponent as InfluencerIcon } from '../../svgs/wizard/OS/influencer.svg';
import { ReactComponent as AffiliateIcon } from '../../svgs/wizard/OS/affiliate.svg';
import { ReactComponent as InstantMessageIcon } from '../../svgs/wizard/OS/instant-message.svg';
import { ReactComponent as PaidAdsIcon } from '../../svgs/wizard/OS/paid-ads.svg';
import { ReactComponent as MobileIcon } from '../../svgs/wizard/OS/mobile.svg';
import { ReactComponent as EmailIcon } from '../../svgs/wizard/OS/email.svg';
import { ReactComponent as ContentIcon } from '../../svgs/wizard/OS/content.svg';
import { ReactComponent as SocialMediaIcon } from '../../svgs/wizard/OS/social-media.svg';
import { ReactComponent as PayPerClickIcon } from '../../svgs/wizard/OS/pay-per-click.svg';
import { ReactComponent as DataAnalyticsIcon } from '../../svgs/wizard/OS/data-analytics.svg';
import { ReactComponent as VirtualAssistantIcon } from '../../svgs/wizard/OS/virtual-assistant.svg';
import { ReactComponent as OffPageSEOIcon } from '../../svgs/wizard/OS/off-page-seo.svg';
import { ReactComponent as OnPageSEOIcon } from '../../svgs/wizard/OS/on-page-seo.svg';
import { ReactComponent as LocalSEOIcon } from '../../svgs/wizard/OS/local-seo.svg';
import { ReactComponent as TechSEOIcon } from '../../svgs/wizard/OS/tech-seo.svg';
import { ReactComponent as T3dIcon } from '../../svgs/wizard/Apps/3d.svg';
import { ReactComponent as T3dsIcon } from '../../svgs/wizard/Apps/3ds.svg';
import { ReactComponent as AbstractIcon } from '../../svgs/wizard/Apps/abstract.svg';
import { ReactComponent as AndroidIcon } from '../../svgs/wizard/Apps/android.svg';
import { ReactComponent as AngularIcon } from '../../svgs/wizard/Apps/angular.svg';
import { ReactComponent as ApiIcon } from '../../svgs/wizard/Apps/api.svg';
import { ReactComponent as AppsIcon } from '../../svgs/wizard/Apps/apps.svg';
import { ReactComponent as ArabicIcon } from '../../svgs/wizard/Apps/arabic.svg';
import { ReactComponent as BigCommerceIcon } from '../../svgs/wizard/Apps/bigcommerce.svg';
import { ReactComponent as BlockchainIcon } from '../../svgs/wizard/Apps/blockchain.svg';
import { ReactComponent as BlogIcon } from '../../svgs/wizard/Apps/blog.svg';
import { ReactComponent as BookingIcon } from '../../svgs/wizard/Apps/booking.svg';
import { ReactComponent as BootstrapIcon } from '../../svgs/wizard/Apps/bootstrap.svg';
import { ReactComponent as BrushesIcon } from '../../svgs/wizard/Apps/brushes.svg';
import { ReactComponent as BusinessIcon } from '../../svgs/wizard/Apps/business.svg';
import { ReactComponent as CSharpIcon } from '../../svgs/wizard/Apps/c-sharp.svg';
import { ReactComponent as CIcon } from '../../svgs/wizard/Apps/c.svg';
import { ReactComponent as CanvaIcon } from '../../svgs/wizard/Apps/canva.svg';
import { ReactComponent as CartoonIcon } from '../../svgs/wizard/Apps/cartoons.svg';
import { ReactComponent as ChineseIcon } from '../../svgs/wizard/Apps/chinese.svg';
import { ReactComponent as ClipArtIcon } from '../../svgs/wizard/Apps/clip-art.svg';
import { ReactComponent as CMSMadeSimpleIcon } from '../../svgs/wizard/Apps/cmsmadesimple.svg';
import { ReactComponent as CommunityIcon } from '../../svgs/wizard/Apps/community.svg';
import { ReactComponent as Concrete5Icon } from '../../svgs/wizard/Apps/concrete5.svg';
import { ReactComponent as WebConsultingIcon } from '../../svgs/wizard/Apps/consulting.svg';
import { ReactComponent as ContentfulIcon } from '../../svgs/wizard/Apps/contentful.svg';
import { ReactComponent as CSSIcon } from '../../svgs/wizard/Apps/css.svg';
import { ReactComponent as DirectoryIcon } from '../../svgs/wizard/Apps/directory.svg';
import { ReactComponent as DrupalIcon } from '../../svgs/wizard/Apps/Drupal.svg';
import { ReactComponent as DudaIcon } from '../../svgs/wizard/Apps/duda.svg';
import { ReactComponent as DutchIcon } from '../../svgs/wizard/Apps/dutch.svg';
import { ReactComponent as EcommerceIcon } from '../../svgs/wizard/Apps/ecommerce.svg';
import { ReactComponent as EnglishIcon } from '../../svgs/wizard/Apps/english.svg';
import { ReactComponent as EntertainmentIcon } from '../../svgs/wizard/Apps/entertainment.svg';
import { ReactComponent as EventIcon } from '../../svgs/wizard/Apps/event.svg';
import { ReactComponent as ExpressionEngineIcon } from '../../svgs/wizard/Apps/expressionengine.svg';
import { ReactComponent as FigmaIcon } from '../../svgs/wizard/Apps/figma.svg';
import { ReactComponent as FloralIcon } from '../../svgs/wizard/Apps/floral.svg';
import { ReactComponent as FontsIcon } from '../../svgs/wizard/Apps/fonts.svg';
import { ReactComponent as FrenchIcon } from '../../svgs/wizard/Apps/french.svg';
import { ReactComponent as GermanIcon } from '../../svgs/wizard/Apps/german.svg';
import { ReactComponent as GhostIcon } from '../../svgs/wizard/Apps/ghost.svg';
import { ReactComponent as GraphicsIcon } from '../../svgs/wizard/Apps/graphics.svg';
import { ReactComponent as HobbyIcon } from '../../svgs/wizard/Apps/hobby.svg';
import { ReactComponent as HTML5Icon } from '../../svgs/wizard/Apps/html-5.svg';
import { ReactComponent as HubspotIcon } from '../../svgs/wizard/Apps/hubspot.svg';
import { ReactComponent as IconsIcon } from '../../svgs/wizard/Apps/icons.svg';
import { ReactComponent as IllustrationsIcon } from '../../svgs/wizard/Apps/illustrations.svg';
import { ReactComponent as IllustratorIcon } from '../../svgs/wizard/Apps/illustrator.svg';
import { ReactComponent as InformationalIcon } from '../../svgs/wizard/Apps/informational.svg';
import { ReactComponent as InteractiveIcon } from '../../svgs/wizard/Apps/interactive.svg';
import { ReactComponent as IosIcon } from '../../svgs/wizard/Apps/ios.svg';
import { ReactComponent as ItalianIcon } from '../../svgs/wizard/Apps/italian.svg';
import { ReactComponent as JapanIcon } from '../../svgs/wizard/Apps/japan.svg';
import { ReactComponent as JavaIcon } from '../../svgs/wizard/Apps/java.svg';
import { ReactComponent as JavaScriptIcon } from '../../svgs/wizard/Apps/javascript.svg';
import { ReactComponent as JimdoIcon } from '../../svgs/wizard/Apps/jimdo.svg';
import { ReactComponent as JoomlaIcon } from '../../svgs/wizard/Apps/Joomla.svg';
import { ReactComponent as KenticoIcon } from '../../svgs/wizard/Apps/kentico.svg';
import { ReactComponent as KotlinIcon } from '../../svgs/wizard/Apps/kotlin.svg';
import { ReactComponent as LandingPageIcon } from '../../svgs/wizard/Apps/landing-page.svg';
import { ReactComponent as LandingPagesDesignIcon } from '../../svgs/wizard/Apps/landing-pages-design.svg';
import { ReactComponent as LineArtIcon } from '../../svgs/wizard/Apps/line-art.svg';
import { ReactComponent as MagentoIcon } from '../../svgs/wizard/OS/magento.svg';
import { ReactComponent as MatlabIcon } from '../../svgs/wizard/Apps/matlab.svg';
import { ReactComponent as MediaWikiIcon } from '../../svgs/wizard/Apps/MediaWiki.svg';
import { ReactComponent as MembershipIcon } from '../../svgs/wizard/Apps/membership.svg';
import { ReactComponent as MemorialIcon } from '../../svgs/wizard/Apps/memorial.svg';
import { ReactComponent as MockupsIcon } from '../../svgs/wizard/Apps/mockups.svg';
import { ReactComponent as ModxIcon } from '../../svgs/wizard/Apps/modx.svg';
import { ReactComponent as NewsMagazineIcon } from '../../svgs/wizard/Apps/news.svg';
import { ReactComponent as NonProfitIcon } from '../../svgs/wizard/Apps/nonprofit.svg';
import { ReactComponent as NoSQLIcon } from '../../svgs/wizard/Apps/nosql.svg';
import { ReactComponent as OnlineForumIcon } from '../../svgs/wizard/Apps/onlineforum.svg';
import { ReactComponent as OpenCartIcon } from '../../svgs/wizard/Apps/opencart.svg';
import { ReactComponent as PerlIcon } from '../../svgs/wizard/Apps/perl.svg';
import { ReactComponent as PersonalIcon } from '../../svgs/wizard/Apps/personal.svg';
import { ReactComponent as PetitionIcon } from '../../svgs/wizard/Apps/petition.svg';
import { ReactComponent as PhotosIcon } from '../../svgs/wizard/Apps/photos.svg';
import { ReactComponent as PhotoshopIcon } from '../../svgs/wizard/Apps/photoshop.svg';
import { ReactComponent as PHPIcon } from '../../svgs/wizard/Apps/php.svg';
import { ReactComponent as PortfolioIcon } from '../../svgs/wizard/Apps/portfolio.svg';
import { ReactComponent as PortugalIcon } from '../../svgs/wizard/Apps/portugal.svg';
import { ReactComponent as PrestaShopIcon } from '../../svgs/wizard/Apps/PrestaShop.svg';
import { ReactComponent as PWAIcon } from '../../svgs/wizard/Apps/pwa.svg';
import { ReactComponent as PythonIcon } from '../../svgs/wizard/Apps/python.svg';
import { ReactComponent as ReactNativeIcon } from '../../svgs/wizard/Apps/react-native.svg';
import { ReactComponent as ReactIcon } from '../../svgs/wizard/Apps/react.svg';
import { ReactComponent as ResponsiveWebIcon } from '../../svgs/wizard/Apps/responsive-web.svg';
import { ReactComponent as RubyOnRailsIcon } from '../../svgs/wizard/Apps/ruby-on-rails.svg';
import { ReactComponent as RustIcon } from '../../svgs/wizard/Apps/rust.svg';
import { ReactComponent as RIcon } from '../../svgs/wizard/OS/rprogramming.svg';
import { ReactComponent as ScalaIcon } from '../../svgs/wizard/Apps/scala.svg';
import { ReactComponent as SchoolIcon } from '../../svgs/wizard/Apps/school.svg';
import { ReactComponent as ShopifyIcon } from '../../svgs/wizard/Apps/shopify.svg';
import { ReactComponent as SignsSymbolsIcon } from '../../svgs/wizard/Apps/signs-symbols.svg';
import { ReactComponent as SilverStripeIcon } from '../../svgs/wizard/Apps/silverstripe.svg';
import { ReactComponent as SiteMigrationIcon } from '../../svgs/wizard/Apps/site-migration.svg';
import { ReactComponent as SitecoreIcon } from '../../svgs/wizard/Apps/sitecore.svg';
import { ReactComponent as SketchIcon } from '../../svgs/wizard/Apps/sketch.svg';
import { ReactComponent as SocialNetworkIcon } from '../../svgs/wizard/Apps/social-network.svg';
import { ReactComponent as SpanishIcon } from '../../svgs/wizard/Apps/spanish.svg';
import { ReactComponent as SQLIcon } from '../../svgs/wizard/Apps/sql.svg';
import { ReactComponent as SquareSpaceIcon } from '../../svgs/wizard/Apps/squarespace.svg';
import { ReactComponent as StartupIcon } from '../../svgs/wizard/Apps/startup.svg';
import { ReactComponent as SubscriptionIcon } from '../../svgs/wizard/Apps/subscription.svg';
import { ReactComponent as SwiftIcon } from '../../svgs/wizard/Apps/swift.svg';
import { ReactComponent as TemplatesIcon } from '../../svgs/wizard/Apps/templates.svg';
import { ReactComponent as TravelIcon } from '../../svgs/wizard/Apps/travel.svg';
import { ReactComponent as TypeScriptIcon } from '../../svgs/wizard/Apps/typescript.svg';
import { ReactComponent as Typo3Icon } from '../../svgs/wizard/Apps/typo3.svg';
import { ReactComponent as UikitsIcon } from '../../svgs/wizard/Apps/ui-kit.svg';
import { ReactComponent as UmbracoIcon } from '../../svgs/wizard/Apps/umbraco.svg';
import { ReactComponent as VectorIcon } from '../../svgs/wizard/Apps/vector.svg';
import { ReactComponent as VueIcon } from '../../svgs/wizard/Apps/vue.svg';
import { ReactComponent as WatercolorIcon } from '../../svgs/wizard/Apps/watercolor.svg';
import { ReactComponent as WeddingIcon } from '../../svgs/wizard/Apps/wedding.svg';
import { ReactComponent as WeeblyIcon } from '../../svgs/wizard/Apps/weebly.svg';
import { ReactComponent as WireframeIcon } from '../../svgs/wizard/Apps/wireframe.svg';
import { ReactComponent as WixIcon } from '../../svgs/wizard/Apps/wix.svg';
import { ReactComponent as WordPressIcon } from '../../svgs/wizard/OS/WordPress.svg';
import { ReactComponent as WooCommerceIcon } from '../../svgs/wizard/Apps/woocommerce.svg';

import { ReactComponent as BengaliIcon } from '../../svgs/wizard/OS/bengali.svg';
import { ReactComponent as CzechIcon } from '../../svgs/wizard/OS/czech.svg';
import { ReactComponent as FinnishIcon } from '../../svgs/wizard/OS/finland.svg';
import { ReactComponent as GreekIcon } from '../../svgs/wizard/OS/greek.svg';
import { ReactComponent as HebrewIcon } from '../../svgs/wizard/OS/hebrew.svg';
import { ReactComponent as HindiIcon } from '../../svgs/wizard/OS/hindi.svg';
import { ReactComponent as IndonesianIcon } from '../../svgs/wizard/OS/indonesia.svg';
import { ReactComponent as KoreanIcon } from '../../svgs/wizard/OS/korea.svg';
import { ReactComponent as HausaYorubaIcon } from '../../svgs/wizard/OS/nigeria.svg';
import { ReactComponent as PolishIcon } from '../../svgs/wizard/OS/poland.svg';
import { ReactComponent as RussianIcon } from '../../svgs/wizard/OS/russian.svg';
import { ReactComponent as SwedishIcon } from '../../svgs/wizard/OS/swedish.svg';
import { ReactComponent as UkraineIcon } from '../../svgs/wizard/OS/ukraine.svg';
import { ReactComponent as UrduIcon } from '../../svgs/wizard/OS/urdu.svg';
import { ReactComponent as VietnamIcon } from '../../svgs/wizard/OS/vietnam.svg';

const iconMap = {
	BusinessFormation: <BusinessFormationIcon />,
	Contracts: <ContractsIcon />,
	Consulting: <ConsultingIcon />,
	Immigration: <ImmigrationIcon />,
	Trademarks: <TrademarksIcon />,
	Tax: <TaxIcon />,
	BooksCleanup: <BooksCleanupIcon />,
	Bookkeeping: <BookkeepingIcon />,
	Quickbooks: <QuickbooksIcon />,
	Payroll: <PayrollIcon />,
	TaxPrep: <TaxPrepIcon />,
	CloudAccounts: <CloudAccountsIcon />,
	Retirement: <RetirementIcon />,
	Influencer: <InfluencerIcon />,
	Affiliate: <AffiliateIcon />,
	InstantMessage: <InstantMessageIcon />,
	PaidAds: <PaidAdsIcon />,
	Mobile: <MobileIcon />,
	Email: <EmailIcon />,
	Content: <ContentIcon />,
	SocialMedia: <SocialMediaIcon />,
	PayPerClick: <PayPerClickIcon />,
	DataAnalytics: <DataAnalyticsIcon />,
	VirtualAssistant: <VirtualAssistantIcon />,
	OffPageSEO: <OffPageSEOIcon />,
	OnPageSEO: <OnPageSEOIcon />,
	LocalSEO: <LocalSEOIcon />,
	TechSEO: <TechSEOIcon />,
	Duda: <DudaIcon />,
    Drupal: <DrupalIcon />,
    CSS: <CSSIcon />,
	WooCommerce: <WooCommerceIcon />,
	WordPress: <WordPressIcon />,
    Wix: <WixIcon />,
    Wireframe: <WireframeIcon />,
	Weebly: <WeeblyIcon />,
    Wedding: <WeddingIcon />,
    Watercolor: <WatercolorIcon />,
    VueJS: <VueIcon />,
    Vector: <VectorIcon />,
    Umbraco: <UmbracoIcon />,
    Uikits: <UikitsIcon />,
    Typo3: <Typo3Icon />,
    TypeScript: <TypeScriptIcon />,
    Travel: <TravelIcon />,
    Templates: <TemplatesIcon />,
    Swift: <SwiftIcon />,
	Subscription: <SubscriptionIcon />,
    Startup: <StartupIcon />,
    SquareSpace: <SquareSpaceIcon />,
    SQL: <SQLIcon />,
    Spanish: <SpanishIcon />,
    SocialNetwork: <SocialNetworkIcon />,
    Sketch: <SketchIcon />,
    Sitecore: <SitecoreIcon />,
    SiteMigration: <SiteMigrationIcon />,
    SilverStripe: <SilverStripeIcon />,
	SignsSymbols: <SignsSymbolsIcon />,
	Shopify: <ShopifyIcon />,
	School: <SchoolIcon />,
	Scala: <ScalaIcon />,
	Rust: <RustIcon />,
	RubyOnRails: <RubyOnRailsIcon />,
	ResponsiveWeb: <ResponsiveWebIcon />,
	React: <ReactIcon />,
	ReactNative: <ReactNativeIcon />,
	R: <RIcon />,
	Python: <PythonIcon />,
	PWA: <PWAIcon />,
	PrestaShop: <PrestaShopIcon />,
	Portugal: <PortugalIcon />,
	Portfolio: <PortfolioIcon />,
	PHP: <PHPIcon />,
	Photoshop: <PhotoshopIcon />,
	Photos: <PhotosIcon />,
	Petition: <PetitionIcon />,
	Perl: <PerlIcon />,
	Personal: <PersonalIcon />,
	OpenCart: <OpenCartIcon />,
	OnlineForum: <OnlineForumIcon />,
	NoSQL: <NoSQLIcon />,
	NonProfit: <NonProfitIcon />,
	NewsMagazine: <NewsMagazineIcon />,
	Modx: <ModxIcon />,
	Mockups: <MockupsIcon />,
    Memorial: <MemorialIcon />,
    Membership: <MembershipIcon />,
    MediaWiki: <MediaWikiIcon />,
    MatLab: <MatlabIcon />,
    Magento: <MagentoIcon />,
    LineArt: <LineArtIcon />,
    LandingPagesDesign: <LandingPagesDesignIcon />,
    LandingPage: <LandingPageIcon />,
    Kotlin: <KotlinIcon />,
    Kentico: <KenticoIcon />,
    Joomla: <JoomlaIcon />,
    Jimdo: <JimdoIcon />,
    JavaScript: <JavaScriptIcon />,
    Java: <JavaIcon />,
    Japan: <JapanIcon />,
    Italian: <ItalianIcon />,
    Ios: <IosIcon />,
    Interactive: <InteractiveIcon />,
    Informational: <InformationalIcon />,
    Illustrator: <IllustratorIcon />,
    Illustrations: <IllustrationsIcon />,
    Icons: <IconsIcon />,
    HubSpot: <HubspotIcon />,
    WebConsulting: <WebConsultingIcon />,
    CMSMadeSimple: <CMSMadeSimpleIcon />,
	HTML: <HTML5Icon />,
	Hobby: <HobbyIcon />,
    Graphics: <GraphicsIcon />,
    Ghost: <GhostIcon />,
    German: <GermanIcon />,
    French: <FrenchIcon />,
    Fonts: <FontsIcon />,
    Floral: <FloralIcon />,
    Figma: <FigmaIcon />,
    ExpressionEngine: <ExpressionEngineIcon />,
	Event: <EventIcon />,
    Entertainment: <EntertainmentIcon />,
    English: <EnglishIcon />,
    Ecommerce: <EcommerceIcon />,
    Dutch: <DutchIcon />,
	Directory: <DirectoryIcon />,
	Contentful: <ContentfulIcon />,  
	Community: <CommunityIcon />,
	Concrete5: <Concrete5Icon />,
    ClipArt: <ClipArtIcon />,
    Chinese: <ChineseIcon />,
    Cartoon: <CartoonIcon />,
    Canva: <CanvaIcon />,
    C: <CIcon />,
    CSharp: <CSharpIcon />,
    Business: <BusinessIcon />,
    Brushes: <BrushesIcon />,
    Bootstrap: <BootstrapIcon />,
    Booking: <BookingIcon />,
    Blog: <BlogIcon />,
    Blockchain: <BlockchainIcon />,
    BigCommerce: <BigCommerceIcon />,
    Arabic: <ArabicIcon />,
    Apps: <AppsIcon />,
    Api: <ApiIcon />,
    Angular: <AngularIcon />,
    Android: <AndroidIcon />,
    Abstract: <AbstractIcon />,
    T3ds: <T3dsIcon />,
	T3d: <T3dIcon />,

	Bengali: <BengaliIcon />,
	Czech: <CzechIcon />,
	Finnish: <FinnishIcon />,
	Greek: <GreekIcon />,
	Hebrew: <HebrewIcon />,
	Hindi: <HindiIcon />,
	Indonesian: <IndonesianIcon />,
	Korean: <KoreanIcon />,
	HausaYoruba: <HausaYorubaIcon />,
	Polish: <PolishIcon />,
	Russian: <RussianIcon />,
	Swedish: <SwedishIcon />,
	Ukraine: <UkraineIcon />,
	Urdu: <UrduIcon />,
	Vietnam: <VietnamIcon />,
};

export { ItemsGrid as AppItems } from '../ItemsGrid';

const AppIcon = styled.div`
	height: 56px;
	width: 56px;
	flex: 0 0 56px;
	svg {
		height: 100%;
		width: 100%;
		transition: filter 300ms ${props => props.theme.effects.easeOutSine};
		filter: grayscale(1);
	}
	position: relative; /* because we have a pos-absolute backdrop with gradient */
`;

const AppHeading = styled.header`
	flex: 0 0 calc(100% - 72px);
	margin: 0 0 0 16px;
	position: relative; /* because we have a pos-absolute backdrop with gradient */
`;

const AppSubtitle = styled.h4`
	font-size: 16px;
	line-height: 12px;
	color: #7b8794;
	margin: 10px 0 0;
	padding: 0;
	font-weight: 400;
`;

const AppTitle = styled.h3`
	font-size: 22px;
	line-height: 1;
	font-weight: bold;
	color: ${props => props.theme.neutrals.darkest};
	margin: 0;
	padding: 0;
`;

const AppLicense = styled.div`
	font-size: 12px;
	position: absolute;
	bottom: 8px;
	right: 8px;
	color: ${props => props.theme.neutrals.light};
	font-style: italic;
`;

const AppItemWrap = styled.div`
	min-height: 85px;
	padding: 8px;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	cursor: pointer;
	background: ${props => props.theme.background};
	box-shadow: 0 0 0 1px ${props => props.theme.neutrals.lightTwo},
		0 0 4px 0 transparent;
	transition: all 300ms ${props => props.theme.effects.easeOutSine};
	border-radius: 2px;
	position: relative;

	&::before {
		position: absolute;
		content: '';
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: linear-gradient(
			90deg,
			rgb(26, 115, 232) 0%,
			rgb(9 56 226) 100%
		);
		border-color: #1e88e5;
		opacity: 0;
		transition: all 300ms ${props => props.theme.effects.easeOutSine};
		border-radius: 2px;
	}

	&:hover {
		box-shadow: 0 1px 2px 0 ${props => props.theme.neutrals.lightOne},
			0 0 4px 0 ${props => props.theme.neutrals.lightOne};
		${AppIcon} {
			svg {
				filter: grayscale(0);
			}
		}
	}

	&&:active,
	&&.active {
		box-shadow: 0 1px 2px 0 ${props => props.theme.neutrals.lightOne},
			0 0 6px 0 ${props => props.theme.neutrals.lightOne};
		${AppIcon} {
			svg {
				// filter: grayscale(0);
				filter: none;
				path {
					&:first-of-type {
						// fill: white;
					}
				}
			}
		}
	}
	&&.active {
		${AppIcon} {
			svg {
				path {
					&:first-of-type {
						fill: white;
					}
				}

				&.fill-full {
					path {
						fill: white;
					}
				}
				&.fill-under-g {
					g {
						path {
							fill: none;
						}
						g {
							path {
								fill: white;
								&:last-of-type {
									fill: #0052cc;
								}
							}
						}
					}
				}

				&.fill-full-under-g {
					g {
						path {
							fill: none;
						}
						g {
							path {
								fill: white;
							}
						}
					}
				}

				&.fedora {
					path {
						&:nth-of-type(1) {
							fill: white;
						}
						&:nth-of-type(2) {
							fill: white;
						}
						&:nth-of-type(3) {
							fill: #0052cc;
						}
					}
				}
			}
		}
	}
	&.active {
		${AppTitle} {
			color: ${props => props.theme.neutrals.lightTwo};
			color: white;
		}
		${AppSubtitle} {
			color: ${props => props.theme.neutrals.lightTwo};
		}
		${AppLicense} {
			color: ${props => props.theme.neutrals.lightTwo};
		}
		&::before {
			opacity: 1;
		}
	}
`;

export function AppItem({
	active = false,
	license = false,
	subtitle = null,
	icon,
	title,
	onClick,
}) {
	return (
		<div>
			<AppItemWrap
				tabIndex="0"
				className={classNames({ active })}
				onClick={onClick}
			>
				<AppIcon>{iconMap[icon]}</AppIcon>
				<AppHeading>
					<AppTitle>{title}</AppTitle>
					{subtitle ? <AppSubtitle>{subtitle}</AppSubtitle> : null}
				</AppHeading>
				{license ? <AppLicense>license options</AppLicense> : null}
			</AppItemWrap>
		</div>
	);
}
